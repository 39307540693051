import naja from 'naja';
import 'bootstrap';

export default class AlertExtension {

    initialize(naja) {
        naja.addEventListener('init', (event) => {
            this._init(document);
        });

        naja.snippetHandler.addEventListener('afterUpdate', (event) => {
            this._init(event.detail.snippet);
        });

    }

    _init(block) {
        const alertList = block.querySelectorAll('.alert')
        alertList.forEach(function (alert) {
            new bootstrap.Alert(alert)
        })
    }

}